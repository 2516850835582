
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class FormFilterPopup extends Vue {
   @Prop({ type: Array, required: false }) forms?: any;

   @Prop({ type: Object, required: false }) formFilter ?: any;

   get categories() {
     return ['Construction', 'HSE', 'I&E', 'Maintenance', 'Production', 'Regulatory', 'None'].map((s) => ({ text: s, value: s }));
   }

   get availableFormNames() {
     return this.forms.map((s) => ({ text: s.title, value: s.title }));
   }

   dropdownTitlesLocation: any = []

    dropdownLastEditorLocation: any = []

     dropdownCausesLocation: any = []

   formFilterDefault: any = {
     categoriesSelected: [],
     titlesSelected: [],
     lastEditorSelected: [],
     lastEditStartDate: '',
     lastEditEndDate: '',
   }

   dataLoading = false;

     dropdownCategoriesLocation: any = [];

     resetFiltering() {
          this.$emit('reset-filtering', this.formFilterDefault);
     }

     applyFiltering() {
       this.$emit('apply-filtering', this.formFilter);
     }

     didSelectDropdown(e) {
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownCategoriesLocation = [rect.x, rect.y + 75];
     }

     didSelectSystemsDropdown(e) {
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownTitlesLocation = [rect.x, rect.y + 50];
     }

     hideFormTitlesDropdown() {
       this.dropdownTitlesLocation = [];
     }

     	async didFormTitlesSelectDropdownOption(e: any) {
       if (e) {
         const rect = e.srcElement.parentElement.getBoundingClientRect();
         this.dropdownTitlesLocation = [rect.x, rect.y + 75];
       }
     }

  	async didLastEditorSelectDropdownOption(e: any) {
       if (e) {
         const rect = e.srcElement.parentElement.getBoundingClientRect();
         this.dropdownLastEditorLocation = [rect.x, rect.y - 175];
       }
     }

     get reassignUsers() {
       return accountModule.reassignmentList.map((i) => ({
         // @ts-ignore
         text: i.name,
         value: i.name,
       })).sort((a, b) => a.text.localeCompare(b.text));
     }

     hideLastEditorDropdown() {
       this.dropdownLastEditorLocation = [];
     }

     didSelectCausesDropdown(e) {
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownCausesLocation = [rect.x + 190, rect.y - 80];
     }

  	async didCategoriesSelectDropdownOption() {
       //  this.dropdownCategoriesLocation = [];
     }

     hideCategoiesDropdown() {
       this.dropdownCategoriesLocation = [];
     }
}

